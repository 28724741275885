import React, { useState } from 'react';

import './App.css';
import Footer from './components/footer/Footer';
import SectionMain from './components/sectionMain/SectionMain';
import SectionForm from './components/sectionForm/SectionForm';
import SectionInfo from './components/sectionInfo/sectionInfo';

function App ({data}) {

 const [formActive, setFormActive] = useState(false);
 const [activeUmrah, setActiveUmrah] = useState(false);
 const [activeNoble, setActiveNoble] = useState(true);
    const [nobleGender, setNobleGender] = useState('Men');
 
  const [formData, setFormData]= useState({
    name: '',
    dayGregorian: '21 Apr 2023',
    dayHijri: '30 Ram. 1444',
    timeFromTo: '03:30-03:59',
    arrivingTime: '01:00'
  })
    
  const getFilterValue =( key, value) => {
    const newfilterValue = formData;
    Object.keys(newfilterValue).map((item)=> {
        if(item === key ) {
          newfilterValue[item] = value;
        }
      }
    )
   return setFormData(newfilterValue);
  } 
  return (
    <div className="wrapper">
          <SectionMain formData={formData}
              activeUmrah={activeUmrah} setActiveUmrah={setActiveUmrah}
              activeNoble={activeNoble} setActiveNoble={setActiveNoble}
              setNobleGender={setNobleGender}
         />
        <SectionForm formActive={formActive} formData={formData} setFormData={setFormData} getFilterValue={getFilterValue}/>
        <Footer isFormActive ={formActive} setForm={setFormActive} />
        <SectionInfo formData={formData} data={data} activeUmrah={activeUmrah} setActiveUmrah={setActiveUmrah}
              activeNoble={activeNoble} setActiveNoble={setActiveNoble} nobleGender={nobleGender} />
    </div>  
  )
}


export default App;
